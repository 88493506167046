import { Main } from './components/Main/Main';
import './App.css';

function App() {
  return (

    <Main />
  );
}

export default App;
