import './GoToTopButton.css';
import { useEffect, useState } from 'react';

export function GoToTopButton() {
  const [showTopButton, setShowTopButton] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  function handleScroll():void {
    const position:number = window.scrollY;
    setScrollPosition(position);
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    scrollPosition > 300 ? setShowTopButton(true) : setShowTopButton(false);
  }, [scrollPosition]);
  return (
    <a href="#searchStart">
      <button className={`goTopButton ${showTopButton && 'showTopButton'}`} title="Go to top of the page">

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-up-circle"
          viewBox="0 0 16 16"
          data-testid="svgTestElement"
        >
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
          />
        </svg>

      </button>
    </a>
  );
}
